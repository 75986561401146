import React, { Component } from "react";
import {
    MDBBtn,
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBCardText
} from "mdbreact";
import {Formik, Form, Field} from "formik";
import * as Yup from "yup";
import '../App.css';

class CardSuggestion extends Component {

    constructor() {
        super();
        this.state = {
            headers: {
                headers: {
                    "x-api-key": process.env.REACT_APP_KEY_URL,
                    "Content-Type": "application/json"
                }
            },
            suggestion: {
                userId: "",
                reclationTexte: ""
            },
            notification: ""
        };
    }

    onSave = async event => {

        let saved = await fetch( process.env.REACT_APP_BASE_URL +  "/user/make-a-reclamation", {
            method: "POST",
            body: JSON.stringify(this.state.suggestion),
                ...this.state.headers
        });
        this.setState({suggestion:{userId: "", reclationTexte:""}, notification: "Votre message est bien envoyé"});

        setTimeout(()=>{this.setState({notification: ""})}, 5000);
    };

    handleChange = prop => event => {

        let suggestion = { ...this.state.suggestion};
        suggestion[prop] = event.target.value;

        this.setState({suggestion});
    };

    render() {

        let initialValues = {userId:this.state.suggestion.userId, reclationTexte:this.state.suggestion.reclationTexte};
        return(
            <div class="blue-grey lighten-5">
                <MDBContainer>

                    <MDBRow className="mb-5">

                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="8" className="text-justify" >
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <MDBCardText>
                                <h4> <strong> Tu vois une amélioration sur l'application ? 💪 </strong> </h4>
                            </MDBCardText>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <MDBCardText>
                                <h4> <strong>Tu veux nous contacter pour un partenariat ? 🤑 </strong> </h4>
                            </MDBCardText>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <MDBCardText>
                                <h4> <strong> C'est par ici 👉 </strong> </h4>
                            </MDBCardText>
                        </MDBCol>
                        <MDBCol md="4">
                            <Formik enableReinitialize initialValues={initialValues} validationSchema={Yup.object().shape({
                                userId: Yup.string().required('Required'),
                                reclationTexte: Yup.string().required('Required')
                                })} onSubmit={this.onSave} render={({values, touched,errors, handleChange, handleBlur,handleSubmit, 
                                    isSubmitting, validateField, validateForm,  setFieldValue}) =>(

                                <Form onSubmit={handleSubmit}>
                                    <label />
                                    <Field name="userId" placeholder="Email *" type="email"  className="form-control" onChange={this.handleChange("userId")}/>
                                        {errors.userId && touched.userId ? <div>{errors.userId}</div> : null}
                                    <label />
                                    <Field name="reclationTexte" placeholder="Message " component="textarea" rows="3"  className="form-control" onChange={this.handleChange("reclationTexte")}/>
                                    {errors.reclationTexte && touched.reclationTexte ? <div>{errors.reclationTexte}</div> : null}
                                    <div className="text-center" >
                                        <MDBBtn color="grey" onClick={() => handleSubmit()} disabled={isSubmitting} >
                                            {isSubmitting ? "Envoie en cours ..." : "Envoyer"}
                                        </MDBBtn>
                                    </div>
                                     <div>{this.state.notification}</div>
                                </Form>
                                )}
                            />
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <br />
                    </MDBRow>
                </MDBContainer>
            </div>
        )
    }

}

export default CardSuggestion;