import React, { Component } from 'react';
import {
    MDBContainer,
    MDBBtn,
    MDBCol,
    MDBRow,
    MDBFooter,
    MDBBtnGroup,
    MDBIcon,
    MDBCardText
} from "mdbreact";
import logo from '../assets/app/logo@3x.png';
import buttonIOS from '../assets/os/btn_AppStore.png';
import buttonAndroid from '../assets/os/btn_GooglePlay.png';
import iconPhone from '../assets/icons/icon_phone.png';
import iconLocalisation from '../assets/icons/icon_localisation.png';
import iconMail from '../assets/icons/icon_mail.png';
import '../App.css';  


class Footer extends Component {

    render() {
        return (
            <MDBFooter color="black" >
                <span>{" "}&nbsp;</span>
                
                    <MDBContainer fluid className="text-center text-md-left" >
                        <MDBRow>
                            <MDBCol md="6">
                                <h5> Rejoins-nous sur BlaBlaFoot ! </h5>
                                <a href="http://www.blablafoot.com/" >
                                    <img src={logo} class="img-fluid" width="120" height="100" alt="logo BlaBlaFoot" />
                                </a>

                                <MDBRow>
                                    <MDBCol>
                                        <MDBBtnGroup>
                                            <MDBBtn color="black" href="https://play.google.com/store/apps/details?id=com.blablafoot.app" >
                                                <img src={buttonAndroid} class="img-fluid" alt="logo GooglePlay" />
                                            </MDBBtn>
                                            <MDBBtn color="black" href="https://apps.apple.com/fr/app/blablafoot/id1391088896" >
                                                <img src={buttonIOS} class="img-fluid" alt="logo AppStore"  />
                                            </MDBBtn>
                                        </MDBBtnGroup>
                                    </MDBCol>
                                </MDBRow>

                            </MDBCol>
                            <MDBCol md="6" >
                                <h5 className="text-justify" >
                                    <span>{" "}&nbsp;</span>
                                    <span>{" "}&nbsp;</span>
                                    <span>{" "}&nbsp;</span>
                                    <strong>Contact</strong>
                                </h5>

                                <h6 className="text-justify">
                                    <img src={iconMail} class="img-fluid" alt="icon mail" /> 
                                    <span>{" "}&nbsp;</span>
                                    contact@blablafoot.com
                                </h6>

                                <h6 className="text-justify"> 
                                    <img src={iconPhone} class="img-fluid" alt="icon phone" />
                                    <span>{" "}&nbsp;</span>
                                    +33 64003923 /+33 626686168
                                </h6>

                                <h6 className="text-justify" >
                                    <img src={iconLocalisation} class="img-fluid" alt="localisation" />
                                    <span>{" "}&nbsp;</span>
                                    ME SOLUTIONS 11 rue Magdebourg 75116 Paris 
                                </h6>
                                <MDBRow>
                                    <br />
                                </MDBRow>

                                <MDBCol>
                                    <MDBCardText>
                                        <h5> 
                                            <a href="https://fr-fr.facebook.com/BlaBlaFoot18/" > 
                                                <MDBIcon fab icon="facebook-f" className="pr-1" />
                                            </a>  
                                            <span>{" "}&nbsp;</span>
                                            <a href="https://twitter.com/BlaBlaFoot_app?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" >
                                                <MDBIcon fab icon="twitter" className="pr-1" />
                                            </a> 
                                            <span>{" "}&nbsp;</span>
                                            <a href="https://fr.linkedin.com/company/blablafoot" >
                                                <MDBIcon fab icon="linkedin-in" className="pr-1" />
                                            </a> 
                                            <span>{" "}&nbsp;</span>
                                            <a href="https://apps.apple.com/fr/app/blablafoot/id1391088896" >
                                                <MDBIcon fab icon="apple" className="pr-1" />
                                            </a>
                                            <span>{" "}&nbsp;</span>
                                            <a href="https://play.google.com/store/apps/details?id=com.blablafoot.app" >
                                                <MDBIcon fab icon="android" className="pr-1" />
                                            </a> 
                                            <span>{" "}&nbsp;</span>
                                            <a href="https://www.instagram.com/blablafoot.officiel/?hl=fr" >
                                                <MDBIcon fab icon="instagram" className="pr-1" />
                                            </a> 
                                            <span>{" "}&nbsp;</span>
                                            <a href="https://www.snapchat.com/add/blablafoot" >
                                                <MDBIcon fab icon="snapchat" className="pr-1" />
                                            </a> 
                                        </h5>
                                    </MDBCardText>               
                                </MDBCol>               

                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </MDBFooter>
        )
    }

}

export default Footer;