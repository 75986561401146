import React, { Component } from 'react';
import {
    MDBRow,
    MDBCol,
    MDBBtnGroup     
} from "mdbreact";
import logo from '../assets/app/logo@3x.png';
import buttonLive from '../assets/icons/btn_live.png';
import buttonNosNews from '../assets/icons/btn_nosNews.png';
import buttonReplay from '../assets/icons/btn_replay.png';
import buttonLireVideo from '../assets/icons/btn_lire_video.png';
import buttonFermeture from '../assets/icons/btn_close.png';
import ReactPlayer from 'react-player/youtube';
import '../css/Header.css';

class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showVideo : false
        };
    }

    handleVideo = () =>{
      
        this.setState({showVideo : true});
    };

    closeVideo = () =>{
        this.setState({showVideo : false});
    };
    
    render() {
       
        return(

            <div className="header">

                <MDBRow className="mt-3" >
                    <MDBCol id="logoBlablafoot" className="text-left" >
                        <a href="http://www.blablafoot.com/" >
                            <img src={logo} width="170" height="120" class="img-fluild" alt="logo Blablafoot" />
                        </a>
                    </MDBCol>
                
                    <MDBCol className="ml-5" >
                        {(this.props.url == undefined || this.props.url !=="/news/" ) && 
                        <MDBBtnGroup>
                        
                           <a href="/allNews" >
                                <img id="bouton_news" src={buttonNosNews}  class="img-fluid" alt="bouton News" />
                            </a>

                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                          

                            <a href="http://replay.blablafoot.com/" >
                                <img id="bouton_replay" src={buttonReplay}  class="img-fluid" alt="bouton Replay" />
                            </a>

                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            

                            <a href="http://www.blablafoot.com/live/" >
                                <img id="bouton_live" src={buttonLive}  class="img-fluid" alt="bouton Live" />
                            </a>
                            <span>{" "}&nbsp;</span>
                            
                        </MDBBtnGroup>
                        }
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol className="text-right" >
                        {this.state.showVideo &&
                            <a onClick={this.closeVideo} >
                                <img src={buttonFermeture} class="img-fluid" width="30" height="30" alt="bouton fermeture" />
                            </a>
                        }
                        <span>{" "}&nbsp;</span>
                    </MDBCol>
                </MDBRow>

                <MDBRow>
                    <MDBCol>
                        {this.state.showVideo &&
                            <ReactPlayer playing={true} url='https://www.youtube.com/watch?v=6UUxnRcyHvs&feature=youtu.be' width="100%" />
                        }
                    </MDBCol>
                </MDBRow>
                
                <MDBRow className="mt-5" >

                </MDBRow>

                <MDBRow className="mt-5" >

                </MDBRow>

                <MDBRow className="mt-5" >

                </MDBRow>
        
                <MDBRow className="mt-3" >
                    <MDBCol>
                        {!this.state.showVideo &&
                            <a onClick={this.handleVideo} >
                                <img src={buttonLireVideo} />
                            </a>
                        }                        
                    </MDBCol>
                </MDBRow>
            </div>
               
        )
    }

}

export default Header;