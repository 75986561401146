import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import Article from './pages/Articles/Article';
import AllArticles from "./pages/Articles/AllArticles";
import News from './pages/News/News';
import AllNews from './pages/News/AllNews';
import DetailArticle from './pages/Articles/DetailArticle';
import ReactGA from 'react-ga';
import './App.css';

function initizeAnalytics() {
  ReactGA.initialize("UA-140416109-1")
  ReactGA.pageview(window.location.pathname)
}

function App() {

  initizeAnalytics()
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={Article}/>
          <Route exact path={"/news"} component={News}/>
          <Route exact path={"/news/"} component={News}/>
          <Route exact path={"/allNews"} component={AllNews}/>
          <Route exact path={"/allArticles"} component={AllArticles}/>
          <Route exact path={"/detailArticle/:article_id"} component={DetailArticle} />
        </Switch>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
