import { TYPES } from "../actions/action-types";
const initialState = {articles: []} ;
export default function reducerArticles(state = initialState, action){

  switch(action.type){

    case TYPES.READ_NEWS:
      return {prevArticles: state.articles, articles: action.payload, isNewComment: false};

    case TYPES.CREATE_COMMENT:

      let publicationDate = action.payload && action.payload.publicationDate;
      return {prevArticles: state.articles,  articles: [action.payload], isNewComment: true, publicationDate: publicationDate};
  }
  return state;
}