import React, { Component, Fragment } from "react";
import {
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdbreact";
import _ from "lodash";
class ViewComments extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: 5
        };

        this.loadMore = this.loadMore.bind(this);
    }
    loadMore(){
        this.setState((prev) => {
            return {visible: prev.visible + 5};
        });
    }
    render() {
        let {comments} = this.props;

        var options = {
            day: "numeric",
            month: "long",
            year: "numeric",
            hour: "numeric",
            minute: "numeric"
        }
        return(
            <Fragment>
                <MDBContainer>
                    {
                        comments && _.reverse(comments) && _.slice(comments, 0, this.state.visible).map((comment) => {

                        return(

                            <div>
                                <MDBRow>
                                    <MDBCol className="mx-5" >
                                        <p className="text-sm-left">
                                            {
                                                <div>

                                                    <MDBRow>
                                                        <h5>
                                                                <strong>{comment.pseudo} -</strong>
                                                        </h5>
                                                            <span>{" "}&nbsp;</span> <a className="text-sm" > {new Intl.DateTimeFormat("fr", options).format(comment.commentDate * 1000) } </a>
                                                    </MDBRow>

                                                    <MDBRow>
                                                        <p className="text-justify" >
                                                            {comment.message}
                                                        </p>
                                                    </MDBRow>

                                                    <MDBRow className="mt-2">

                                                    </MDBRow>
                                                </div>
                                            }
                                        </p>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                            )
                        })
                    }
                    {this.state.visible < comments.length &&
                        <a onClick={this.loadMore} class="font-weight-bold " > Voir plus </a>
                    }
                    <MDBRow className="mt-5">

                    </MDBRow>

                </MDBContainer>
            </Fragment>
        )
    }
}

export default ViewComments;