import React, { Component,  Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
    MDBContainer,
    MDBRow,
    MDBCol,
    MDBCardTitle,
    MDBCardText,
    MDBCard,
    MDBBtnGroup,
    MDBCardBody,
    MDBCollapse,
    MDBIcon,
    MDBModal,
    MDBModalBody,
    MDBModalHeader
} from "mdbreact";
import { FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon
} from 'react-share';
import IconeDate from './../../assets/icons/icon_date.png';
import IconLike from './../../assets/icons/like_icon.png';
import IconReply from './../../assets/icons/reply_icon.png';
import IconComment from './../../assets/icons/icon_comments.png';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import Header from './../../components/Header';
import ViewComments from "../Comments/ViewComments";
import NewComment from "../Comments/NewComment";
import {createComment, detailsArticle} from "../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { number } from "yup";
import './../../App.css';

class DetailArticle extends Component {

    constructor(props) {

        super(props);

        this.state = {

            view: false,
            newComment: false,
            collapseID : "collapse1",
            currentIndex: "",
            modalShare: false,
            sharedArticle : null
        };
    }

    componentWillMount() {

        this.props.detailsArticle(this.props.match.params.article_id);
    }

    handleComment = (index, articles, isNewComment, action, viewComment) => {

        this.setState({currentIndex:index});
        let checkCommentStatus = this.props.articles.isNewComment;
        let newStatus= false;

        if(action === "addNew"){

            if(isNewComment == true){
                this.setState({newComment: false});
            }else{
                this.setState({newComment: true});
            }
        }

        if(action === "view"){

            if(viewComment == true){
                this.setState({view: false});
            }else{
                this.setState({view: true});
            }
        }
        articles && articles.map((comment, number) => {
            if(number === index) {
                comment.commentNews =  this.state.newComment;
                comment.viewComment = this.state.view;
                comment.collapseID = this.state.view;
            }
            return comment;
        });
        return articles;
    }

    toggleShare = (article) => {

        this.setState({
            modalShare: !this.state.modalShare,
            sharedArticle: article
        });
    }
    render() {

        let {articles} = this.props.articles || this.props.articles.articles;
        let previousArticles = this.props.articles.prevArticles;
        let difference = _.differenceBy(previousArticles, articles, 'creationDate');

        difference.forEach(diff=>{
            articles.push(diff);
        });

        var options = {
            day: "numeric",
            month: "long",
            hour: "numeric",
            minute: "numeric"
        }

        var baseUrl = window.location.origin;
        const {collapseID} = this.state;
        let isNewComment = this.props.articles.isNewComment;
        let publicationDate = this.props.articles.publicationDate;

        return(
            <Fragment>
                <Header />
                <div id="conteneur" >
                    <MDBContainer>
                        <MDBRow className="mt-5" >
                            <MDBCol md="1" >
                                <MDBCardTitle className="text-left" >
                                    <a  href="http://www.blablafoot.com/" className="black-text" > <strong> <u>Retour </u> </strong></a>
                                </MDBCardTitle>
                            </MDBCol>
                            <MDBCol md="11" >
                                <MDBCardTitle className="text-center" tag="h1" > <strong> Details de l'Article</strong> </MDBCardTitle>
                            </MDBCol>
                        </MDBRow>

                        <div id="bloc_card" className="mt-5" >

                            {
                                articles && _.reverse(_.sortBy(articles, "publicationDate")).map((article, index) => {

                                    if(article && article.publicationDate) {
                                        if((index == this.state.currentIndex)) {
                                            article.collapseID = this.state.newComment == true ? this.state.newComment : this.state.view;
                                            article.viewComment = this.state.newComment == true ? this.state.newComment : this.state.view;

                                            article.commentNews = this.state.newComment;
                                        }
                                    }

                                    return(
                                        <div key={article.creationDate} creationDate={article.creationDate} >

                                            <div id="cardNews" >
                                                <div id="card_contenu">
                                                    <MDBRow>
                                                        <span>{" "}&nbsp;</span>
                                                        <span>{" "}&nbsp;</span>
                                                    </MDBRow>

                                                    <MDBCol>
                                                        <MDBRow>
                                                            <MDBCol md="9">
                                                                <MDBCardTitle className="text-left" >
                                                                    <h3 class="white-text" > <strong> {article.title && article.title.toUpperCase()}</strong> </h3>
                                                                </MDBCardTitle>
                                                            </MDBCol>

                                                            <MDBCol>
                                                                <MDBCardText className="text-left">
                                                                <h6  class="white-text" ><img src={IconeDate} class="img-fluid" alt="icone Date" />  <span>{" "}&nbsp;</span> {new Intl.DateTimeFormat("fr", options).format(article.publicationDate * 1000) } </h6>
                                                                </MDBCardText>
                                                            </MDBCol>
                                                        </MDBRow>

                                                        <MDBRow>
                                                            <MDBCol md="12" >
                                                                <MDBCardText className="text-left" >
                                                                    <h6 class="white-text" > Auteur : {article.authorName} </h6>
                                                                </MDBCardText>
                                                            </MDBCol>
                                                        </MDBRow>

                                                        <MDBRow className="mt-3" >
                                                            <MDBCol>
                                                                {article.mediaType ==='image' &&
                                                                    <img id="card_img" src={article.mediaURL} width="100%" class="img-fluid"  alt="Image Article" />
                                                                }

                                                                {article.mediaType ==='video' &&
                                                                    <ReactPlayer controls url={article.mediaURL} width="100%"/>
                                                                }
                                                            </MDBCol>
                                                        </MDBRow>

                                                        <MDBRow className="mt-3" >
                                                            <div class="white-text" >
                                                                <MDBCol className="text-justify" >
                                                                    {article.content &&
                                                                        <p>{parse(article.content)}</p>
                                                                    }
                                                                </MDBCol>
                                                            </div>
                                                        </MDBRow>

                                                    </MDBCol>
                                                </div>
                                            </div>

                                            <MDBCard id="card_hatg" >

                                                <MDBRow className="mt-3" >
                                                    <MDBCol>
                                                        <MDBCardText className="text-right px-3" >
                                                            {article.comments &&
                                                                <a onClick={()=> this.handleComment(index, articles, this.state.newComment, "view", this.state.view)} class="blue-text font-weight-bold" >
                                                                    {article.comments.length} Commentaires {" "}
                                                                    <MDBIcon icon={article.collapseID === true ? 'angle-up' : 'angle-down'} />
                                                                </a>
                                                            }
                                                        </MDBCardText>
                                                    </MDBCol>
                                                </MDBRow>

                                                <hr className="my+1" />

                                                <div id="bloc_like">
                                                    <MDBRow>
                                                        <MDBCol className="text-left" >
                                                            <MDBBtnGroup>
                                                                <MDBCol>
                                                                    <MDBCardText>
                                                                        <a>
                                                                            <img src={IconLike} class="img-fluid" width="20" height="18"  alt="Icone like" /> {" "}
                                                                        </a>
                                                                    </MDBCardText>
                                                                </MDBCol>

                                                                <MDBCol>
                                                                    <MDBCardText>
                                                                        <a onClick={() => this.toggleShare(article)} >
                                                                            <img src={IconReply} class="img-fluid" width="25" height="25" alt="Icone share" /> {" "}
                                                                        </a>
                                                                    </MDBCardText>
                                                                </MDBCol>
                                                            </MDBBtnGroup>
                                                        </MDBCol>

                                                        <MDBCol>
                                                            <MDBCardText>
                                                                <a onClick={()=> this.handleComment(index, articles, this.state.newComment, "addNew", this.state.view) } >
                                                                    <h7 class="blue-text font-weight-bold" >
                                                                        <img src={IconComment} />
                                                                    </h7>
                                                                </a>
                                                            </MDBCardText>
                                                        </MDBCol>

                                                        <MDBCol className="text-right" >

                                                        </MDBCol>
                                                    </MDBRow>
                                                </div>

                                                <MDBRow>
                                                    <MDBCardBody>
                                                        {(article.commentNews) &&
                                                            <MDBCollapse id="collapse1" isOpen={collapseID}>
                                                                <NewComment creationDate={article.creationDate} status={isNewComment} />
                                                            </MDBCollapse>
                                                        }
                                                    </MDBCardBody>
                                                </MDBRow>

                                                <MDBRow>
                                                    <MDBCardBody>
                                                        {(article.viewComment) &&
                                                            <MDBCollapse id="collapse1" isOpen={article.collapseID} >
                                                                <ViewComments newsId={article.creationDate} comments={article.comments} />
                                                            </MDBCollapse>
                                                        }
                                                    </MDBCardBody>
                                                </MDBRow>

                                            </MDBCard>

                                            <MDBModal centered id="modal_Share" isOpen={this.state.modalShare} toggle={this.toggleShare} >
                                                <MDBModalHeader className="text-right" toggle={this.toggleShare} > Partager </MDBModalHeader>
                                                { this.state.sharedArticle && (
                                                    <MDBModalBody>
                                                        <FacebookShareButton
                                                            quote={"A lire sur BLABLAFOOT : \n"+this.state.sharedArticle.title+" \n"}
                                                            url={baseUrl+"/detailArticle/"+this.state.sharedArticle.creationDate}
                                                        >
                                                            <FacebookIcon size="40" round={true} />
                                                        </FacebookShareButton>
                                                            <span>{" "}&nbsp;</span>
                                                            <span>{" "}&nbsp;</span>
                                                        <TwitterShareButton
                                                            title={"📰 A lire sur BLABLAFOOT : \n"+this.state.sharedArticle.title+" \n"}
                                                            url={baseUrl+"/detailArticle/"+this.state.sharedArticle.creationDate+" \n"}
                                                            via="blablafoot.com"
                                                        >
                                                            <TwitterIcon size="40" round={true} />
                                                        </TwitterShareButton>
                                                            <span>{" "}&nbsp;</span>
                                                            <span>{" "}&nbsp;</span>
                                                        <LinkedinShareButton
                                                            title={"📰 A lire sur BLABLAFOOT : \n"+this.state.sharedArticle.title+" \n"}
                                                            url={"http://blablafoot.com/detailArticle/"+this.state.sharedArticle.creationDate}
                                                        >
                                                            <LinkedinIcon size="40" round={true} />
                                                        </LinkedinShareButton>
                                                    </MDBModalBody>
                                                )
                                                }
                                            </MDBModal>

                                            <MDBRow className="mt-5">

                                            </MDBRow>

                                        </div>
                                    )
                                })
                            }

                        </div>

                    </MDBContainer>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {

    return {
        articles: state.articles
    };
  };

  const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ detailsArticle, createComment }, dispatch),
  });

export default connect(mapStateToProps, mapDispatchToProps)(DetailArticle);
