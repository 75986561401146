import React, {Component, Fragment } from "react";
import {
    MDBRow,
    MDBCol,
    MDBCardTitle,
    MDBBtnGroup,
    MDBBtn
} from "mdbreact";
import IconConsultant from './../assets/icons/icon_consultant.png';
import IconMicro from './../assets/icons/icon_micro.png';
import IconTelecharger from './../assets/icons/icon_telecharger.png';
import IconSmartphone from './../assets/icons/icon_smartphone.png';
import buttonIOS from './../assets/os/btn_AppStore.png';
import buttonAndroid from './../assets/os/btn_GooglePlay.png';
class CardBlablafoot extends Component {


    render() {

        return(
            <Fragment>
            <MDBRow>
                <MDBCol md="6">
                    <MDBRow>
                        <MDBCardTitle className="text-left">
                            <h2> <strong>Télécharge BlablaFoot,</strong> </h2>
                            <h2> <strong>écoute et prends part</strong> </h2>
                            <h2> <strong>à des débats passionnés ! </strong> </h2>
                        </MDBCardTitle>
                    </MDBRow>

                    <MDBRow className="mt-5">
                        <MDBCol md="2">
                            <img src={IconConsultant} class="img-fluid" alt="icon Consultant" />
                        </MDBCol>

                        <MDBCol md="10">
                            <MDBCardTitle className="text-left">
                                <h3>
                                    <strong>Le consultant c'est toi !</strong>
                                </h3>
                            </MDBCardTitle>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="2">

                        </MDBCol>

                        <MDBCol md="10" className="text-justify" >
                            <h5>
                                Prends position sur les nos débats diffusés en direct et viens défendre
                                ton point de vue sur le sujet.
                                La mauvaise foi est un plus !
                            </h5>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mt-5">
                        <MDBCol md="2">
                            <img src={IconMicro} class="img-fluid" alt="icon Micro"  />
                        </MDBCol>

                        <MDBCol md="10" className="text-justify" >
                            <h3>
                                <strong> Ecouter le direct </strong>
                            </h3>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="2">

                        </MDBCol>

                        <MDBCol md="10" className="text-justify" >
                            <h5>
                                Découvre des orateurs talenteux qui débattent avec passion !
                            </h5>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mt-5">
                        <MDBCol md="2">
                            <img src={IconTelecharger} width="50" class="img-fluid" alt="icon Telecharger" />
                        </MDBCol>

                        <MDBCol md="10" className="text-justify" >
                            <h3>
                                <strong> Télécharge l'application </strong>
                            </h3>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="2">

                        </MDBCol>
                        <MDBCol md="10" className="text-justify" >
                            <h5>
                                📣   BlaBlaFoot,  la seule radio où le consultant  c'est vous !
                            </h5>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow>
                        <MDBCol md="2">

                        </MDBCol>

                        <MDBCol md="10" className="text-justify" >
                            <h5>
                                Ecoute ou participe au débat et deviens chroniqueur en cliquant simplement sur le bouton « Devenir chroniqueur ».
                            </h5>
                        </MDBCol>
                    </MDBRow>

                </MDBCol>

                <MDBCol md="6" className="text-center" >
                    <img src={IconSmartphone} />
                </MDBCol>
            </MDBRow>

            <MDBRow className="mt-5" >
                <MDBCol>
                    <MDBBtnGroup size="md" >
                        <MDBBtn color="black" href="https://play.google.com/store/apps/details?id=com.blablafoot.app" >
                            <img src={buttonAndroid} class="img-fluid" width="120" height="30" alt="bouton Android" />
                        </MDBBtn>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                            <span>{" "}&nbsp;</span>
                        <MDBBtn  color="black" href="https://apps.apple.com/fr/app/blablafoot/id1391088896"  >
                            <img src={buttonIOS} class="img-fluid" width="120" height="30" alt="bouton IOS"  />
                        </MDBBtn>
                    </MDBBtnGroup>
                </MDBCol>
            </MDBRow>
        </Fragment>
        )
    }

}

export default CardBlablafoot;