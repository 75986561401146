import React, { Component} from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
    MDBContainer,
    MDBCol,
    MDBRow,
    MDBInput,
    MDBIcon
} from "mdbreact";
import {Formik, Form} from "formik";
import { reduxForm } from "redux-form";
import * as Yup from "yup";
import {createComment, latestNews, allNews, detailsArticle}  from "../../actions/index";

let formConfig = {
    form: "createPostForm",
    fields: ["pseudo", "message"],
    initialValues: { pseudo: "", message: "" },
  };
class NewComment extends Component {

    constructor(props){
        super(props);
        this.state = {
            comments: {
                creationDate: this.props.creationDate,
                pseudo: "",
                message: ""
            }
        };
    }

    onSave = async () => {

        var commentaires =  {...this.state.comments};
        var data = {newsId: this.props.creationDate, comments: {pseudo: commentaires.pseudo, message:commentaires.message}};
        let saved =  this.props.createComment(data);

        this.setState({comments:{pseudo: "", message:""}, notification: "Votre commentaire est  envoyé"});
    };

    handleChange = prop => event => {

        let comments = { ...this.state.comments};
        comments[prop] = event.target.value;
        this.setState({comments});
    };

    render() {

        let initialValues = {commentDate:this.state.comments.commentDate, pseudo:this.state.comments.pseudo, message:this.state.comments.message};
        const {
            fields: { pseudo, message },
            handleSubmit,
            errors,
          } = this.props;

        return(
            <div>
            <MDBContainer>
                <MDBRow>
                    <MDBCol>
                        <Formik enableReinitialize initialValues={initialValues} validationSchema={Yup.object().shape({
                            message: Yup.string().required('Saisir le commentaire !')
                            })} onSubmit={() => this.onSave()}  render={({value, touched, errors, handleChange,
                                handleSubmit}) =>(

                                <Form onSubmit={handleSubmit} >
                                    <div className="form-group">
                                        <MDBInput name="pseudo" type="text" label="Nickname" className="form-control" value={this.state.comments.pseudo} onChange={this.handleChange("pseudo")} />
                                        {errors.pseudo && touched.pseudo ? <div>{errors.pseudo}</div> : null}
                                        <MDBInput name="message" type="textarea" label="Comment" className="form-control" value={this.state.comments.message} onChange={this.handleChange("message")} />
                                        {errors.message && touched.message ? <div>{errors.message}</div> : null}
                                        <br />
                                        <a onClick={() => handleSubmit()} >
                                            <h5 class="blue-text" >
                                                <strong>Envoyer {" "}
                                                    <MDBIcon icon="arrow-circle-right" />
                                                </strong>
                                            </h5>
                                        </a>
                                    </div>
                                </Form>
                            )}
                        />
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        articles: state.articles.articles
    }
}
const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({latestNews, allNews, detailsArticle, createComment}, dispatch),

  });

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(reduxForm(formConfig)(NewComment));