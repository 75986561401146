import React, { Component,  Fragment } from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCol
} from "mdbreact";
import IconAllArticle from './../../assets/icons/icon_tous_articles.png';
import CardSuggestion from './../../components/CardSuggestion';
import logoWinamax from './../../assets/Partenaires/logo_Winamax.png';
import Header from './../../components/Header';
import CardBlablafoot from '../../components/CardBlablafoot';
import AllArticles from "./AllArticles";
import {latestNews}  from "../../actions/index";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Article extends Component {

    constructor(props) {

        super(props);

        this.state = {
            newComment: true,
            modalShare: false
        };
    }

    componentWillMount(){

        this.props.latestNews();
    }

    toggleShare = () => {

        this.setState({
            modalShare: !this.state.modalShare
        });
    }
    render() {

        const {articles, view}=this.props;


        return(
            <Fragment>

                <Header />

                <div id="conteneur" className="mt-5" >
                <MDBContainer>

                    <CardBlablafoot />

                    <MDBRow className="mt-5" >
                        <MDBCol md="4" className="text-left" >
                            <a  href="https://blablafoot.com/news/" >
                                <h2 className="black-text">
                                    <strong>  Nos Articles à la une </strong>
                                </h2>
                            </a>
                        </MDBCol>

                        <MDBCol md="5" >

                        </MDBCol>
                        <MDBCol md="3" >
                            <a href="/allNews" >
                                <h5>
                                    <img src={IconAllArticle} class="img-fluid" alt="Lien tous les articles" />
                                </h5>
                            </a>
                        </MDBCol>
                    </MDBRow>

                    <AllArticles articles={articles} />

                    <MDBRow>
                        <MDBCol md="9">

                        </MDBCol>
                        <MDBCol md="3" >
                            <a href="/allNews" >
                                <h5>
                                    <img src={IconAllArticle} class="img-fluid" alt="lien tous les articles" />
                                </h5>
                            </a>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mt-3" >
                        <MDBCol md="2">
                            <a  href="https://blablafoot.com/news/" >
                                <h2 id="partners" className="black-text">
                                    <strong>  Partenaires </strong>
                                </h2>
                            </a>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className="mb-5" >
                        <MDBCol>
                            <img src={logoWinamax} width="100" height="100" />
                        </MDBCol>
                    </MDBRow>

                </MDBContainer>
            </div>

            <CardSuggestion />

            </Fragment>
        )
    }
}
 const mapStateToProps = (state) => {

     return {
         articles: state.articles
     }
 }
  const mapDispatchToProps = (dispatch) => ({
   ...bindActionCreators({ latestNews }, dispatch)
  });
  export default connect(mapStateToProps, mapDispatchToProps)(Article);;
