import { object } from "yup";
import { TYPES } from "./action-types";

let END_POINT =  `${process.env.REACT_APP_BASE_URL}/admin/news`;
let HEADER = {headers: {
    "x-api-key": process.env.REACT_APP_KEY_URL,
    "Content-Type": "application/json"
}};
export function latestNews(){
    return function(dispatch){
        fetch(`${END_POINT}/retrieve-last-news`,HEADER
            ).then(response =>
             response.json()
          )
        .then(response => {

            return dispatch({ type: TYPES.READ_NEWS, payload: response.object });
        }
        );
    };
}
export function allNews() {
    return function(dispatch){
        fetch(`${END_POINT}/retrieve-last-news?numberOfItems=20`,HEADER
            ).then(response =>
             response.json()
          )
        .then(response => {

            return dispatch({ type: TYPES.READ_NEWS, payload: response.object });
        }
        );
    };
}
export function detailsArticle(articleId){
    return function(dispatch){
        fetch(`${END_POINT}/retrieve-news-by-id?id=${articleId}`,HEADER
            ).then(response =>
             response.json()
          )
        .then(response => {

            return dispatch({ type: TYPES.READ_NEWS, payload: [response] });
        }
        );
    };
}
export function createComment(comment) {
    return function(dispatch){
        fetch(
            `${END_POINT}/add-comment-to-news`, {
            method: "POST",
            body: JSON.stringify(comment),HEADER}
        ).then(response =>
            response.json()
        ).then(response => {

            let result = JSON.parse(response.body);

            return dispatch({ type: TYPES.CREATE_COMMENT, payload:  result.data && result.data.Attributes});

        });
    };
}
