import React from 'react';
import {IntlProvider} from "react-intl";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { Provider } from "react-redux";
import {compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
//import reducers from "./reducers";
import { store } from "./reducers/store";

import * as serviceWorker from './serviceWorker';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

import translate_fr from "../src/locales/fr.json";
import translate_en from "../src/locales/en.json";

//const rootReducer = reducers;
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
//const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
//const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
/* const store = createStoreWithMiddleware(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__() || compose,
); */

const translates = {
  'fr': translate_fr,
  'en': translate_en
};

const language = navigator.language.split(/[-_]/)[0];

 ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <IntlProvider locale={language} translates={translates[language]} >
        <App />
      </IntlProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

/* ReactDOM.render(
  <IntlProvider locale="en">
    <App />
  </IntlProvider>,
  document.getElementById('root')
);
 */


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
